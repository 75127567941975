import React from "react";
import Button from "vgg-ui-react/dist/components/Core/Button";
import InternationalPaymentOne from "./InternationalPaymentOne";
import InternationalPaymentTwo from "./InternationalPaymentTwo";
import { makeTransfer } from "redux/actions/payoutsActions";
import { useDispatch } from "react-redux";

const InternationalPaymentForms = ({
  step,
  loadingCountries,
  countryOptions,
  bankOptions,
  onSecondary,
  handleChange,
  formPayload,
  sending,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const onSecondaryClicked = () => {
    onSecondary();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
    const payload = {
      country: formPayload.stepOne.country, // 1
      metaData: {
        BeneficiaryName: formPayload.stepTwo.BeneficiaryName, // 2
        BeneficiaryAddress: formPayload.stepTwo.BeneficiaryAddress, // 3
        BankName: formPayload.stepOne.BankName, // 4
        BankAddress: formPayload.stepOne.BankAddress, // 5
        SwiftCode: formPayload.stepOne.SwiftCode, // 6
        PaymentPurpose: formPayload.stepTwo.PaymentPurpose, // 7
        RoutingNumber: formPayload.stepOne.RoutingNumber,
        BeneficiaryCurrency: "NGN", // 8
      },
      // TransactionReference: formPayload.stepTwo.TransactionReference, // 9
      destinationAmount: formPayload.stepTwo.destinationAmount
        ? formPayload.stepTwo.destinationAmount
        : 0, // 10
      destinationAccount: formPayload.stepTwo.destinationAccount, // 11 required
    };

    dispatch(makeTransfer({ payload, channel: "Swift" }));
  };

  const requiredValidation = React.useCallback(() => {
    const { stepOne, stepTwo } = formPayload ?? {};
    const stepOneValues = Object.values(stepOne);
    const stepTwoValues = Object.values(stepTwo);

    const isStepOneInvalid = stepOneValues.includes("");
    const isStepTwoInvalid = stepTwoValues.includes("");

    return {
      stepOne: isStepOneInvalid,
      stepTwo: isStepOneInvalid || isStepTwoInvalid,
    };
  }, [formPayload]);

  return (
    <form onSubmit={handleSubmit}>
      {step === 1 && (
        <InternationalPaymentOne
          formValues={formPayload}
          errors={{}}
          onChange={handleChange}
          countryOptions={countryOptions}
          bankOptions={bankOptions}
          loadingCountries={loadingCountries}
        />
      )}
      {step === 2 && (
        <InternationalPaymentTwo
          formValues={formPayload}
          errors={{}}
          onChange={handleChange}
        />
      )}

      <div className="row fade_in pt-2">
        {step === 2 && (
          <div className="col-12">
            <Button
              type="submit"
              className="w-100"
              label={sending ? "Transferring..." : "Transfer"}
              background="blue"
              size="md"
              style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16px",
                letterSpacing: "0.4px",
              }}
              disabled={sending || requiredValidation().stepTwo}
            />
          </div>
        )}

        <div className="col-12">
          <Button
            className="w-100"
            label={step === 2 ? "Go Back" : "Next"}
            background={step === 2 ? "neutral" : "blue"}
            variant={step === 2 ? "ghost" : "primary"}
            color="blue"
            size="md"
            style={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
            }}
            disabled={sending || requiredValidation().stepOne}
            onClick={onSecondaryClicked}
          />
        </div>
      </div>
    </form>
  );
};

export default React.memo(InternationalPaymentForms);
