import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Modal, ModalBody, ModalHeader } from "reactstrap";
import { successMessage } from "redux/actions/notification";
import Initiate from "./components/Initiate";
import TransferForm from "./components/TransferForm";
import CustomSpinner from "shared/CustomSpinner/CustomSpinner";
import "./payoutModal.scss";
import {
  fetchAccountServiceWallets,
  fetchFormConfig,
  fetchSupportedCountries,
  makeTransfer,
  resolveBankAccount,
} from "redux/actions/payoutsActions";
import { debounce } from "lodash";
import { getInvalidAccountMsg } from "helpers/getInvalidAccountMsg";
import Notification from "shared/notifications/notifications";
import InternationalPaymentForms from "./components/InternationalPaymentForms";

const MODAL_SCREENS = {
  initiate: {
    title: "Initiate Payout",
    channel: 0,
  },
  bank: {
    title: "Send to Bank",
    channel: 1,
  },
  mobileMoney: {
    title: "Send to Mobile Money",
    channel: 2,
  },
  internationalPayment: {
    title: "International Payment",
    channel: 3,
  },
};

export const initialInternationalFormPayload = {
  stepOne: {
    BankAddress: "",
    BankName: "",
    country: "",
    RoutingNumber: "",
    SwiftCode: "",
  },
  stepTwo: {
    destinationAccount: "",
    destinationAmount: "",
    BeneficiaryAddress: "",

    BeneficiaryName: "",
    PaymentPurpose: "",
    // TransactionReference: "",
  },
};
const PayoutModal = ({ show, toggleModal }) => {
  const [international, setInternational] = React.useState({
    step: 1,
    formPayload: initialInternationalFormPayload,
  });
  const dispatch = useDispatch();
  const [submitStatus, setSubmitStatus] = useState(null);
  const [fetchedBank, setFetchedBank] = useState(null);
  const [resolvedAcct, setResolvedAcct] = useState(null);
  const [waiting, setWaiting] = useState(true);
  const [activeScreen, setActiveScreen] = useState(MODAL_SCREENS.initiate);
  const { title, channel } = useMemo(() => activeScreen, [activeScreen]);
  const [errorMsg, setErrorMsg] = useState("");

  const {
    transferPayload,
    transferPayloadError,
    makingTransfer,
    walletsPayload,
    loadingWallets,
    supportedCountries,
    loadingSupportedCountries,
    banksPayload,
    banksPayloadError,
    loadingBanks,
    resolveBankAccountLoading,
    resolveBankAccountSuccess,
    resolveBankAccountError,
  } = useSelector(({ payouts }) => payouts);

  useEffect(() => {
    setErrorMsg("");
    dispatch(fetchAccountServiceWallets());
    dispatch(fetchSupportedCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingBanks && fetchedBank) {
      if (banksPayload) {
        if (!banksPayload?.requestSuccessful) {
          setErrorMsg(
            banksPayload?.message ||
              `Unable load ${
                activeScreen.channel === 1 ? "Banks" : "Mobile Operators"
              }`
          );
        }
      }
      if (banksPayloadError) {
        setErrorMsg(
          banksPayloadError?.message ||
            `Unable load ${
              activeScreen.channel === 1 ? "Banks" : "Mobile Operators"
            }`
        );
      }
      setFetchedBank(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banksPayload, loadingBanks, banksPayloadError, setFetchedBank]);

  useEffect(() => {
    if (!makingTransfer && submitStatus) {
      if (transferPayload) {
        if (transferPayload?.requestSuccessful) {
          setActiveScreen(MODAL_SCREENS.initiate);
          setInternational({
            step: 1,
            formPayload: initialInternationalFormPayload,
          });
          dispatch(
            successMessage(transferPayload?.message || "Transfer successful")
          );
          setErrorMsg("");
        } else {
          setErrorMsg(transferPayload?.message || "Unable to make transfer");
        }
      }
      if (transferPayloadError) {
        setErrorMsg(transferPayloadError?.message || "Unable to update data");
      }
      setSubmitStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferPayload, makingTransfer, transferPayloadError, setSubmitStatus]);

  useEffect(() => {
    if (!resolveBankAccountLoading && resolvedAcct) {
      if (resolveBankAccountSuccess) {
        if (!resolveBankAccountSuccess?.requestSuccessful) {
          setErrorMsg(
            resolveBankAccountSuccess?.message ||
              "Unable to resolve account. Please try again later"
          );
        }
      }
      if (resolveBankAccountError) {
        setErrorMsg(
          resolveBankAccountError?.message ||
            "Unable to resolve account. Please try again later"
        );
      }
      setResolvedAcct(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resolveBankAccountLoading,
    resolveBankAccountSuccess,
    resolveBankAccountError,
  ]);

  useEffect(() => {
    const run = async () => {
      setTimeout(() => {
        setWaiting(false);
      }, 500);
    };
    if (waiting) {
      run();
    }
  }, [waiting]);

  const handleCountrySelect = (country) => {
    dispatch(fetchFormConfig({ country, channel }));
  };

  const submitForm = (payload) => {
    setSubmitStatus(true);
    if (payload?.country === "KEN" && channel === 1) {
      payload["metaData"] = {
        BeneficiaryFirstName: payload?.BeneficiaryFirstName,
        BeneficiaryLastName: payload?.BeneficiaryLastName,
        ServiceProviderId: payload?.institutionCode,
      };
      delete payload?.BeneficiaryFirstName;
      delete payload?.BeneficiaryLastName;
    }
    if (payload["destinationAccount"]) {
      const plusCheck = payload["destinationAccount"].split("+");
      payload["destinationAccount"] =
        plusCheck.length > 1 ? plusCheck[1] : payload["destinationAccount"];
    }

    dispatch(makeTransfer({ payload, channel }));
  };

  const handleSubmitInternational = (payload) => {
    setSubmitStatus(true);
    // dispatch(makeTransfer(payload));
  };

  useEffect(() => {
    let timeOut;
    if (errorMsg) {
      timeOut = setTimeout(() => {
        setErrorMsg("");
      }, 8000);
    }

    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [errorMsg]);

  const handleChange = (e, stepName) => {
    const { name, value } = e.target;

    setInternational((prev) => ({
      ...prev,
      formPayload: {
        ...prev.formPayload,
        [stepName]: {
          ...prev.formPayload[stepName],
          [name]: value,
        },
      },
    }));
  };

  const handleGoBack = () => {
    if (MODAL_SCREENS.internationalPayment && international.step > 1) {
      setInternational((prev) => ({
        ...prev,
        step: prev.step - 1,
      }));
    } else {
      setActiveScreen(MODAL_SCREENS.initiate);
    }
  };

  const renderContent = useCallback(() => {
    if (channel) {
      if (channel === 3)
        return (
          <InternationalPaymentForms
            formPayload={international.formPayload}
            step={international.step}
            handleChange={handleChange}
            onSecondary={() => {
              setWaiting(true);
              setErrorMsg("");
              setInternational((prev) => ({
                ...prev,
                step: prev.step === 2 ? 1 : 2,
              }));
            }}
            sending={makingTransfer}
            onSubmit={handleSubmitInternational}
            countryOptions={
              supportedCountries?.responseData?.map((v) => ({
                label: v.countryName,
                value: v.countryCode,
              })) || []
            }
            bankOptions={
              (banksPayload?.data
                ? banksPayload?.data?.responseData?.map((v) => ({
                    label: v.bankName,
                    value: v.bankCode,
                  }))
                : banksPayload?.responseData?.map((v) => ({
                    label: v.bankName,
                    value: v.bankCode,
                  }))) || []
            }
            loadingCountries={loadingSupportedCountries}
            loadingBanks={loadingBanks}
          />
        );
      return (
        <TransferForm
          handleResolveAccount={handleResolveAccount}
          fetchingBanks={loadingBanks}
          setFetchedBank={setFetchedBank}
          onBackClick={() => {
            setWaiting(true);
            setErrorMsg("");
            handleGoBack();
          }}
          countryOptions={
            supportedCountries?.responseData?.map((v) => ({
              label: v.countryName,
              value: v.countryCode,
            })) || []
          }
          sending={makingTransfer}
          submitForm={submitForm}
          loadingWallets={loadingWallets}
          walletOptions={
            walletsPayload?.data?.map((v) => ({
              label: v.currency,
              value: v.currency,
            })) || []
          }
          onCountrySelect={handleCountrySelect}
          channel={channel}
          bankOptions={
            (banksPayload?.data
              ? banksPayload?.data?.responseData?.map((v) => ({
                  label: v.bankName,
                  value: v.bankCode,
                }))
              : banksPayload?.responseData?.map((v) => ({
                  label: v.bankName,
                  value: v.bankCode,
                }))) || []
          }
          dispatch={dispatch}
          resolveAcct={{
            loading: resolveBankAccountLoading,
            payload: resolveBankAccountSuccess?.responseData,
            error: resolveBankAccountError,
          }}
        />
      );
    }

    return (
      <Initiate
        onSelectPayoutType={(type) => {
          setWaiting(true);
          setActiveScreen(MODAL_SCREENS[type]);
        }}
        onClose={toggleModal}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    banksPayload?.data,
    banksPayload?.responseData,
    channel,
    loadingBanks,
    loadingWallets,
    makingTransfer,
    resolveBankAccountError,
    resolveBankAccountLoading,
    resolveBankAccountSuccess?.responseData,
    supportedCountries?.responseData,
    walletsPayload?.data,
    errorMsg,
    international,
  ]);

  const handleResolveAccount = debounce(
    (value, fieldName, country, bankCode) => {
      if (
        (fieldName === "destinationAccount" ||
          fieldName === "institutionCode") &&
        value &&
        country &&
        bankCode
      ) {
        const validValue =
          channel === 1 ? getInvalidAccountMsg(country, value).valid : value;

        if (validValue) {
          dispatch(resolveBankAccount(value, bankCode, country));

          setResolvedAcct(true);
        }
      }
    },
    1500
  );

  return (
    <Modal
      isOpen={show}
      toggle={toggleModal}
      centered
      className="payout-modal"
      backdrop="static"
    >
      <ModalHeader toggle={toggleModal}>
        <Notification />
        <div className="d-flex">
          {channel > 0 && (
            <i
              data-testid="back-icon"
              className="ri-arrow-left-line me-2 pointer"
              onClick={() => handleGoBack()}
            ></i>
          )}
          <div>
            {title}
            {channel === 3 && `(${international.step}/2)`}
            {channel === 3 && (
              <p className="sub-head mt-2">Send money to countries overseas</p>
            )}
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Alert
          color="danger"
          isOpen={!!errorMsg}
          toggle={() => setErrorMsg("")}
        >
          <span className="small">{errorMsg}</span>
        </Alert>
        {waiting ? <CustomSpinner /> : renderContent()}
      </ModalBody>
    </Modal>
  );
};

export default PayoutModal;
